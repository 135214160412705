import {
  recalculateState,
  discoverMails,
} from "./common";
import resources from "../resources";


export default (state) => {
  discoverMails(state);
  resources.forEach((resource) => {
    if (resource.ontick) {
      resource.ontick(state);
    }
  })

  state = recalculateState(state);
  return state;
};
