import deepcopy from "deepcopy";
const commands = {
  nextSubnet: require("./nextSubnet"),
  timetick: require("./timetick"),
};

const saveState = (state) => {
  localStorage.setItem("state", JSON.stringify(state));
};

export default (state, action) => {
  const { what } = action;
  const statecopy = deepcopy(state);
  if (what === "startover") {
    const { initialState } = action;
    saveState(initialState);
    return deepcopy(initialState);
  } else if (what === "onclick") {
    const { resource } = action;
    resource.onclick(statecopy);
    return statecopy;
  }

  const cmd = commands[what];
  const newstate = cmd.default(statecopy, action);
  saveState(newstate);
  return newstate;
  // Error here? Did you include action reducer on top of this file?
};
