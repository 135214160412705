import React, { useState } from "react";
import {
  TouchableOpacity,
  TouchableHighlight,
  Text,
  View,
  Image,
} from "react-native";
import Modal from "modal-react-native-web";
import StartOver from "./StartOver";
import styles from "../styles";

export default ({ state, dispatch, initialState }) => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <TouchableOpacity
        style={styles.menuButton}
        onPress={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <Text style={styles.text}>☰</Text>
      </TouchableOpacity>
      <Modal animationType="slide" transparent={false} visible={modalVisible}>
        <View style={[styles.container, styles.modalContainer]}>
          <View style={styles.logoview}>
            <View>
              <Image
                style={styles.applogo}
                source={require("../../../assets/icon.png")}
              />
            </View>
            <View style={styles.row}>
              <Text style={styles.text}>My Dear Virus</Text>
            </View>
          </View>

          <StartOver
            dispatch={dispatch}
            onPress={() => {
              setModalVisible(false);
            }}
            initialState={initialState}
          />
          <TouchableHighlight
            style={styles.button}
            onPress={() => {
              setModalVisible(false);
            }}
          >
            <Text style={styles.text}>Close</Text>
          </TouchableHighlight>
          <View style={styles.row}>
            <Text style={styles.text}>Build {state.version}</Text>
          </View>
        </View>
      </Modal>
    </>
  );
};
