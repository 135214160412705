export default {
  ram: 0,
  log: [],
  targetIp: "",
  targetRam: 64 * 1024,
  targetHP: 1,
  phase: 1,

  foundMessageIds: [],
  version: 2,

  // // debug
  // followers: 10000000,
  // phase: 2,
};
