import React from "react";
import { View, Text } from "react-native";
import Attribute from "../Attribute";
import CommandButton from "../CommandButton";
import { getDisplayRam, getHp } from "../../reducer/common";
import { useNewTargetAnimation } from "./animation";
import styles from "../../styles";

export default ({ state, dispatch }) => {
  const hp = getHp(state);

  const style = useNewTargetAnimation(state);
  return (
    <View style={[styles.target]}>
      <View style={styles.row}>
        <Attribute text="Subnet" value="1" />
        {state.showNextSubnet ? (
          <CommandButton
            dispatch={dispatch}
            action={{ what: "nextSubnet" }}
            title="Next subnet"
            disabled={false}
          />
        ) : null}
      </View>
      <Attribute text="Target IP" value={state.targetIp} />
      <Attribute text="Target RAM" value={getDisplayRam(state.targetRam)} />
    </View>
  );
};
