import React, { useState } from "react";
import { TouchableOpacity, TouchableHighlight, Text, View } from "react-native";
import Modal from "modal-react-native-web";

import styles from "../styles";

Modal.setAppElement("#root");

export default ({ dispatch, initialState, onPress }) => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <View style={styles.startover}>
      <TouchableOpacity
        style={styles.button}
        onPress={() => {
          setModalVisible(true);
          if (onPress) {
            onPress();
          }
        }}
      >
        <Text style={styles.text}>Start over</Text>
      </TouchableOpacity>

      <Modal animationType="slide" transparent={false} visible={modalVisible}>
        <View style={[styles.container, styles.modalContainer]}>
          <View style={styles.row}>
            <Text style={styles.text}>Do you want to start over?</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.text}>It will reset your game progress.</Text>
          </View>

          <TouchableHighlight
            style={styles.button}
            onPress={() => {
              dispatch({
                what: "startover",
                initialState,
              });
              setModalVisible(!modalVisible);
            }}
          >
            <Text style={styles.text}>Yes</Text>
          </TouchableHighlight>
          <TouchableHighlight
            style={styles.button}
            onPress={() => {
              setModalVisible(!modalVisible);
            }}
          >
            <Text style={styles.text}>No</Text>
          </TouchableHighlight>
        </View>
      </Modal>
    </View>
  );
};
