import React from "react";
import { TouchableOpacity, Text, View } from "react-native";

import styles from "../styles";

export default ({ dispatch, action, title, disabled }) => {
  return (
    <View id={title}>
      <TouchableOpacity
        style={
          disabled ? [styles.button, styles.disabledButton] : styles.button
        }
        onPress={() => {
          if (disabled) {
            return;
          }
          dispatch(action);
        }}
      >
        <Text
          style={disabled ? [styles.text, styles.disabledText] : styles.text}
        >
          {title}
        </Text>
      </TouchableOpacity>
    </View>
  );
};
