import React from 'react';
import { SafeAreaView } from 'react-native';
import { useFonts } from '@expo-google-fonts/inter';
import {
  PTMono_400Regular
} from '@expo-google-fonts/pt-mono'

import { AppLoading } from 'expo';
import MainScreen from './src/js/components/MainScreen';
import styles from './src/js/styles';


export default function App() {
  let [fontsLoaded] = useFonts({
    PTMono_400Regular,
  });

  if (!fontsLoaded) {
    return <AppLoading />;
  }

  return (
    <SafeAreaView style={styles.container}>
      <MainScreen/>
    </SafeAreaView>
  );
}

