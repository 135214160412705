import React, { useEffect } from "react";
import { View, Text } from "react-native";
import CommandButton from "./CommandButton";
import Attribute from "./Attribute";
import styles from "../styles";
import { getDisplayRam } from "../reducer/common";
import resources from "../resources";

export default ({ state, dispatch }) => {
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch({ what: "timetick" });
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      {resources.map((resource) => {
        const visible = resource.btnvisible ? resource.btnvisible(state) : true;
        if (!visible) {
          return null;
        }
        const enabled = resource.canclick ? resource.canclick(state) : true;
        return (
          <View key={resource.id}>
            {resource.text ? (
              <View style={styles.row}>
                <Text style={styles.text}>{resource.text(state)}</Text>
              </View>
            ) : null}
            {resource.btntitle ? (
              <CommandButton
                dispatch={dispatch}
                action={{ what: "onclick", resource }}
                title={resource.btntitle(state)}
                disabled={!enabled}
              />
            ) : null}
          </View>
        );
      })}
    </>
  );
};
