const { kilomega } = require("kilomega");
import mails from "./mails";

export const PROMPT = "[virus@localhost ~] # ";
export const NEXT_SUBNET_BTN_SHOW_HOSTS = 15;
const MAX_LOG_LENGTH = 1024;

const updateIp = (state) => {
  const hosts = state.hosts || 0;
  const g1 = (hosts % 255) + 1;
  const g2 = Math.floor(hosts / 255) % 256;
  const g3 = Math.floor(hosts / 255 / 256) % 256;

  state.targetIp = `192.${g3}.${g2}.${g1}`;
  return state;
};

export const recalculateState = (state) => {
  if (state.log.length > MAX_LOG_LENGTH) {
    state.log.splice(0, state.log.length - MAX_LOG_LENGTH);
  }
  state.showNextSubnet = state.hosts >= NEXT_SUBNET_BTN_SHOW_HOSTS;

  state = updateIp(state);

  // // Show services
  // state.services.forEach((service) => {
  //   if (state.hosts >= service.showAtHosts) {
  //     service.visible = true;
  //   }
  // });

  return state;
};

export const getDisplayRam = (bytes) => {
  const bytesize = kilomega(bytes);
  const intl = new Intl.NumberFormat("en-US", { maximumSignificantDigits: 3 });
  if (!bytesize.symbol && bytes > 10e5) {
    return `${bytes.toExponential(2)}B`;
  }

  return `${intl.format(bytesize.amount)} ${bytesize.symbol}B`;
};

export const discoverMails = (state) => {
  mails.forEach((mail, id) => {
    const found = state.foundMessageIds.find((el) => el === id);
    if (found === undefined && mail.condition(state)) {
      state.foundMessageIds.push(id);
      const lines = mail.text.split("\n");
      state.log.push(`----------`);
      lines.forEach((msg) => {
        state.log.push(msg);
      });
    }
  });
  return state;
};

export const getHp = (state) => {
  // const hp = state.services.reduce((prev, cur) => {
  //   if (cur.hp && cur.count) {
  //     return prev + cur.hp * cur.count;
  //   }
  //   return prev;
  // }, 1);
  // return Math.floor(hp);
  return 1;
};

export const doHit = (state, count = 1) => {
  const hits = count;
  state.hosts += hits;
  state.ram += hits * state.targetRam;
  return state;
};
