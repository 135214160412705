import { StyleSheet, Dimensions } from "react-native";
const { height } = Dimensions.get("window");
export default StyleSheet.create({
  container: {
    backgroundColor: "#000",
    justifyContent: "flex-start",
    height: height,
    // maxWidth: 480,
    // alignSelf: "center",
  },
  log: {
    maxHeight: 180,
    borderWidth: 1,
    borderColor: "#030",
  },
  target: {
    //flex: 1,
  },
  commands: {
    flex: 1,
  },
  startover: {
    //flex: 1,
  },
  row: {
    flexDirection: "row",
    padding: 4,
    marginHorizontal: 8,
    alignItems: "center",
  },
  disabled: {
    color: "#060",
  },
  enabled: {
    color: "#090",
  },
  text: {
    fontFamily: "PTMono_400Regular",
    color: "#090",
  },
  checkmark: {
    fontFamily: "PTMono_400Regular",
    fontSize: 24,
  },
  disabledText: {
    fontFamily: "PTMono_400Regular",
    color: "#060",
  },
  highlight: {
    fontFamily: "PTMono_400Regular",
    color: "#0F0",
  },
  button: {
    backgroundColor: "#000",
    borderWidth: 1,
    borderColor: "#0F0",
    margin: 8,
    padding: 8,
  },
  disabledButton: {
    borderColor: "#060",
  },
  modalContainer: {
    justifyContent: "center",
  },
  progress: {
    borderWidth: 1,
    borderColor: "#090",
    backgroundColor: "#060",
    height: 5,
    margin: 8,
  },
  statsHeader: {
    //paddingRight: 32,
  },
  statsItem: {
    marginLeft: 16,
    marginVertical: 8,
  },
  statsRow: {
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    margin: 8,
  },
  menuButton: {
    backgroundColor: "#000",
    borderWidth: 1,
    borderColor: "#0F0",
    marginRight: 16,
    padding: 8,
  },
  applogo: {
    width: 64,
    height: 64,
    marginBottom: 16,
  },
  logoview: {
    alignSelf: "center",
    alignItems: "center",
    marginBottom: 48,
  },
  commandRow: {
    flexDirection: "row",
    padding: 4,
    marginHorizontal: 8,
    alignItems: "center",
  },
  commandTitle: {
    flex: 1,
  },
  commandButton: {
    flex: 1,
  },
  commandHeader: {
    paddingLeft: 8,
    paddingTop: 16,
  },
});
