import React, { useReducer, useEffect } from "react";
import { ScrollView } from "react-native";

import Stats from "./Stats";
import Log from "./Log";
import Target from "./Target";
import Commands from "./Commands";

import styles from "../styles";

import reduce from "../reducer";
import initialState from "../state";
import { recalculateState } from "../reducer/common";

const savedStateStr = localStorage.getItem("state");
const savedState = savedStateStr ? JSON.parse(savedStateStr) : null;
const adjustedInitialState = recalculateState(initialState);

export default () => {
  // Check saved state version
  const initState =
    savedState.version === adjustedInitialState.version
      ? savedState
      : adjustedInitialState;
  const [state, dispatch] = useReducer(reduce, initState);

  return (
    <>
      <Stats
        state={state}
        dispatch={dispatch}
        initialState={adjustedInitialState}
      />
      <Log state={state} />
      <Target state={state} dispatch={dispatch} />
      <ScrollView style={styles.commands}>
        <Commands state={state} dispatch={dispatch} />
      </ScrollView>
    </>
  );
};
