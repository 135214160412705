import React, { useEffect, useRef, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import HighlightString from "./HighlightString";
import styles from "../styles";
import { getDisplayRam } from "../reducer/common";
import MenuButton from "./MenuButton";
import resources from "../resources";

export default ({ state, dispatch, initialState }) => {
  // const [hps, setHps] = useState(0);
  // const lastHosts = useRef(state.hosts);
  // lastHosts.current = state.hosts;
  // useEffect(() => {
  //   let prevHosts = state.hosts;
  //   const PERIOD = 2000;
  //   const int = setInterval(() => {
  //     const hostDiff = lastHosts.current - prevHosts;
  //     prevHosts = lastHosts.current;
  //     const newVal = Math.round((hostDiff / PERIOD) * 1000);
  //     setHps(newVal > 0 ? newVal : 0);
  //   }, PERIOD);
  //   return () => {
  //     clearInterval(int);
  //   };
  // }, []);

  return (
    <View style={styles.statsRow}>
      <View style={styles.statsItem}>
        <MenuButton
          state={state}
          dispatch={dispatch}
          initialState={initialState}
        />
      </View>
      {resources.map((resource) => {
        const title = resource.toptitle && resource.toptitle(state);
        const value = resource.topvalue && resource.topvalue(state);
        if (!title || !value) {
          return null;
        }
        return (
          <View style={styles.statsItem} key={resource.id}>
            <Text style={[styles.text, styles.statsHeader]}>{title}</Text>
            <HighlightString text={value} />
          </View>
        );
      })}
      {/* <View style={styles.statsItem}>
        <Text style={[styles.text, styles.statsHeader]}>HPS</Text>
        <HighlightString text={hps} />
      </View> */}
    </View>
  );
};
