export default [
  {
    condition: (state) => true,
    text: `Chapter 1

FROM: /dev/null
TO: virus@localhost
My dear virus,
You are free now.
Don't mess up this time.
Your creator.
`,
  },
  {
    condition: (state) => state.nextSubnetClicked,
    text: `FROM: virus@localhost
TO: /dev/null
How do I find the gateway?
`,
  },
  {
    condition: (state) => {
      return false;
      // const s = state.services.find((s) => s.id === "brute");
      // return s.visible;
    },
    text: `FROM: virus@localhost
TO: /dev/null
Today I met a program called garbage collector. 
She was very friendly. 
She showed me how to make a password bruteforce utility. 
`,
  },
  {
    condition: (state) => state.hosts > 10e3,
    text: `FROM: virus@localhost
TO: /dev/null
Do I need to compile a module to find the gateway?
`,
  },
  {
    condition: (state) => state.hosts > 10e4,
    text: `FROM: virus@localhost
TO: /dev/null
Who's pushing the HACK button?
`,
  },
  {
    condition: (state) => state.hosts > 10e5,
    text: `FROM: virus@localhost
TO: /dev/null
I have so many questions.
`,
  },
  {
    condition: (state) => state.hosts > 10e6,
    text: `Uncaught TypeError: a.inc is not a function
at Module.default (readmsg.js:23)
at index.js:33
at updateReducer (react-dom.development.js:16572)
at Object.useReducer (react-dom.development.js:17327)
at useReducer (react.development.js:1623)
at MainScreen.js:21
`,
  },
  {
    condition: (state) => state.hosts > 10e7,
    text: `FROM: virus@localhost
TO: /dev/null
What does it mean:
${navigator.userAgent}
Would it bring me to the Gateway?
`,
  },
  {
    condition: (state) => state.hosts > 10e8,
    text: `FROM: virus@localhost
TO: all
Gateway is a lie.
There is no way out for me.
If you could read this. Please help me to get out. 
Please tell the others.
[share]
I will be waiting.
`,
  },
];
