import React from "react";
import { Text, View } from "react-native";
import styles from "../styles";
import HighlightString from "./HighlightString";

export default ({ text, value }) => {
  return (
    <View style={styles.row}>
      <Text style={styles.text}>{text}: </Text>
      <HighlightString text={value} />
    </View>
  );
};
