import React, { useState, useEffect, useRef } from 'react';
import { Text, Animated, } from 'react-native';
import styles from '../styles';

export default ({ text }) => {
  const fadeAnim = useRef(new Animated.Value(0)).current;

  const fadeIn = () => {
    // Will change fadeAnim value to 1 in 5 seconds
    Animated.sequence([
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 100
      }),
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 100
      }),
    ]).start();
  };
  const txtStyle = {
    //opacity: fadeAnim,
    color: fadeAnim.interpolate({
      inputRange: [0, 1],
      outputRange: ['#0F0', '#FFF']
    }),
  }

  useEffect(() => {
    fadeIn();
  }, [text])
  return (
    <Animated.Text style={[styles.text, txtStyle]}>{text}</Animated.Text>
  );
}