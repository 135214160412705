import React, { useEffect, useRef, useState } from "react";
import {
  Text,
  View,
  ScrollView,
  FlatList,
  TouchableOpacity,
} from "react-native";
import styles from "../styles";

export default ({ state }) => {
  const list = useRef(null);
  useEffect(() => {
    // On the ap start, scroll to the end without animation
    list.current.scrollToEnd({ animated: false });
  }, []);
  useEffect(() => {
    list.current.scrollToEnd({ animated: true });
  }, [state.log.length]);

  const onShare = async () => {
    if (navigator.share) {
      navigator
        .share({
          title: "My Dear Virus",
          text: "Check out My Dear Virus game",
          url: window.location.href,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    }
  };

  const renderItem = (item, num) => {
    if (item === "[share]") {
      if (!navigator.share) {
        return null;
      }

      return (
        <View style={styles.row} key={num}>
          <TouchableOpacity
            style={styles.button}
            onPress={() => {
              onShare();
            }}
          >
            <Text style={styles.text}>Share</Text>
          </TouchableOpacity>
        </View>
      );
    }
    return (
      <View style={styles.row} key={num}>
        <Text style={styles.text}>{item}</Text>
      </View>
    );
  };

  return (
    <ScrollView ref={list} style={styles.log}>
      {state.log.map((item, num) => renderItem(item, num))}
    </ScrollView>
  );
};
