import { getDisplayRam } from "./reducer/common";

const REQS_PER_DB = 50;
const REQS_PER_APP = 10;

export default [
  {
    id: "hosts",
    btntitle: (state) => "hack",
    btnvisible: (state) =>
      (state.hosts <= 320 || !state.autohacks) && state.phase === 1,
    onclick: (state) => {
      state.hosts = (state.hosts || 0) + 1;
    },
    toptitle: (state) => (state.hosts && state.phase === 1 ? "Hosts" : null),
    topvalue: (state) => Math.round(state.hosts || 0),
  },
  {
    id: "ram",
    toptitle: (state) => (state.ram && state.phase === 1 ? "Free RAM" : null),
    topvalue: (state) => getDisplayRam(state.ram || 0),
    btnvisible: (state) => false,
  },
  {
    // TODO base on apps
    id: "autohacks",
    canclick: (state) => state.appservers >= 1,
    onclick: (state) => {
      state.autohacks = (state.autohacks || 0) + 1;
      state.appservers -= 1;
    },
    ontick(state) {
      if (state.phase !== 1) {
        return;
      }
      if (this.btnvisible(state)) {
        state.hosts += state.autohacks || 0;
      }
    },
    btnvisible: (state) =>
      (state.hosts >= 10 || state.autohacks) && state.phase === 1,
    btntitle: (state) => `Install autohack, needs 1 App server`,
    text: (state) =>
      `${state.autohacks || 0} autohacks make ${
        state.autohacks || 0
      } hosts per second`,
  },
  {
    id: "memrelease",
    canclick: (state) => state.hosts >= 1,
    onclick: (state) => {
      state.ram = (state.ram || 0) + 640 * 1024;
      state.hosts -= 1;
    },
    btnvisible: (state) =>
      (state.hosts >= 30 || state.ram) && state.phase === 1,
    btntitle: (state) => `Release host memory`,
  },
  {
    id: "diskrelease",
    canclick: (state) => state.hosts,
    onclick: (state) => {
      state.disk = (state.disk || 0) + 8 * 1025 * 1024;
      state.hosts -= 1;
    },
    btnvisible: (state) =>
      (state.hosts >= 40 || state.disk) && state.phase === 1,
    btntitle: (state) => `Release host disk space`,
    topvisible: (state) => state.disk && state.phase === 1,
    toptitle: (state) =>
      state.disk && state.phase === 1 ? "Disk Space" : null,
    topvalue: (state) => getDisplayRam(state.disk || 0),
  },
  {
    id: "automemrelease",
    canclick: (state) => state.appservers,
    onclick: (state) => {
      state.automemrelease = (state.automemrelease || 0) + 1;
      state.appservers -= 1;
    },
    btnvisible: (state) =>
      (state.automemrelease || state.appservers) && state.phase === 1,
    btntitle: (state) => `Install host memory auto-releaser, needs 1 appserver`,
    text: (state) =>
      `You have ${state.automemrelease || 0} memory auto-releasers`,
    ontick: (state) => {
      if (state.phase !== 1) {
        return;
      }

      const count = state.automemrelease || 0;
      if (state.hosts >= count) {
        state.ram = (state.ram || 0) + 640 * 1024 * count;
        state.hosts -= count;
      }
    },
  },
  {
    id: "autodiskrelease",
    canclick: (state) => state.appservers,
    onclick: (state) => {
      state.autodiskrelease = (state.autodiskrelease || 0) + 1;
      state.appservers -= 1;
    },
    btnvisible: (state) =>
      (state.autodiskrelease || state.appservers) && state.phase === 1,
    btntitle: (state) => `Install disk space auto-releaser, needs 1 appserver`,
    text: (state) =>
      `You have ${state.autodiskrelease || 0} disk space auto-releasers`,
    ontick: (state) => {
      if (state.phase !== 1) {
        return;
      }

      const count = state.autodiskrelease || 0;
      state.disk = (state.disk || 0) + 8 * 1025 * 1024 * count;
      state.hosts -= count;
    },
  },
  {
    id: "database",
    RAM_NEEDED: 4 * 1024 * 1024,
    DISK_NEEDED: 24 * 1024 * 1024,
    canclick(state) {
      return state.ram >= this.RAM_NEEDED && state.disk >= this.DISK_NEEDED;
    },
    onclick(state) {
      state.database = (state.database || 0) + 1;
      state.ram -= this.RAM_NEEDED;
      state.disk -= this.DISK_NEEDED;
    },
    btnvisible(state) {
      return (
        (state.database ||
          state.ram >= this.RAM_NEEDED ||
          state.disk >= this.DISK_NEEDED) &&
        state.phase === 1
      );
    },
    btntitle(state) {
      return `Install database, needs ${getDisplayRam(
        this.RAM_NEEDED
      )} RAM and ${getDisplayRam(this.DISK_NEEDED)} space`;
    },
    topvisible: (state) => state.database && state.phase === 1,
    toptitle: (state) => (state.database && state.phase === 1 ? "DB" : null),
    topvalue: (state) => state.database || 0,
    text: (state) =>
      `Databases can handle ${
        (state.database || 0) * REQS_PER_DB
      } requests per second.`,
  },
  {
    id: "appserver",
    RAM_NEEDED: 2 * 1024 * 1024,
    DISK_NEEDED: 4 * 1024 * 1024,
    _installCount(state) {
      return Math.max(Math.round((state.appservers || 0) * 0.1), 1);
    },
    canclick(state) {
      const count = this._installCount(state);
      return (
        state.ram >= this.RAM_NEEDED * count &&
        state.disk >= this.DISK_NEEDED * count
      );
    },
    onclick(state) {
      const count = this._installCount(state);
      state.appservers = (state.appservers || 0) + count;
      state.ram -= this.RAM_NEEDED * count;
      state.disk -= this.DISK_NEEDED * count;
    },
    btnvisible(state) {
      return (
        (state.appservers ||
          state.ram >= this.RAM_NEEDED ||
          state.disk >= this.DISK_NEEDED) &&
        state.phase === 1
      );
    },
    btntitle(state) {
      const count = this._installCount(state);
      return `Install App ${count} server(s), needs ${getDisplayRam(
        this.RAM_NEEDED * count
      )} RAM and ${getDisplayRam(this.DISK_NEEDED * count)} disk space`;
    },
    topvisible: (state) => state.appservers && state.phase === 1,
    toptitle: (state) => (state.appservers && state.phase === 1 ? "App" : null),
    topvalue: (state) => state.appservers || 0,
    text: (state) =>
      `Application servers can handle ${
        (state.appservers || 0) * REQS_PER_APP
      } requests per second`,
  },
  {
    id: "loadbalancer",
    RAM_NEEDED: 1 * 1024 * 1024,
    DISK_NEEDED: 1 * 1024 * 1024,
    canclick(state) {
      return (
        state.ram >= this.RAM_NEEDED &&
        state.disk >= this.DISK_NEEDED &&
        !state.loadbalancers
      );
    },
    onclick(state) {
      state.loadbalancers = (state.loadbalancers || 0) + 1;
      state.ram -= this.RAM_NEEDED;
      state.disk -= this.DISK_NEEDED;
    },
    btnvisible(state) {
      if (state.loadbalancers) {
        // Do not show the button if it's already installed
        return false;
      }
      return (
        state.ram >= this.RAM_NEEDED &&
        state.disk >= this.DISK_NEEDED &&
        state.phase === 1
      );
    },
    btntitle(state) {
      return !state.loadbalancers
        ? `Install Load balancer, needs ${getDisplayRam(
            this.RAM_NEEDED
          )} RAM and ${getDisplayRam(this.DISK_NEEDED)} space`
        : "Load balancer is installed";
    },
    topvisible: (state) => state.loadbalancers && state.phase === 1,
    toptitle: (state) =>
      state.loadbalancers && state.phase === 1 ? "LB" : null,
    topvalue: (state) => state.loadbalancers || 0,
  },
  {
    id: "nlpmodule",
    btnvisible(state) {
      return state.appservers && state.phase === 1;
    },
    canclick: (state) => state.appservers && !state.nlpmodule,
    btntitle(state) {
      return state.nlpmodule
        ? `Module is installed`
        : `Install natural language processing module`;
    },
    onclick(state) {
      state.nlpmodule = true;
      state.appservers -= 1;
    },
    text: (state) => {
      return `Natural language processing (NLP) module allows written communication with human beings via e-mails, blog-posts and chat services`;
    },
  },
  {
    id: "blogPosts",
    btnvisible(state) {
      return (
        state.loadbalancers &&
        state.appservers &&
        state.database &&
        state.nlpmodule &&
        state.phase === 1
      );
    },
    btntitle(state) {
      return `Post a blogpost.`;
    },
    onclick(state) {
      state.blogposts = (state.blogposts || 0) + 1;
      const oldval = state.requestsPerSecond || 0;
      state.requestsPerSecond = oldval + 1 + Math.floor(Math.random() * oldval);
    },
    text: (state) => {
      const blogposts = state.blogposts || 0;
      const canFullfill = state.reqsCanFullfill || 0;
      const reqs = state.requestsPerSecond || 0;
      state.reqsBehind = reqs - canFullfill;
      return (
        `You have postsed ${blogposts} blogposts, which generates ${reqs} HTTP requests per second.` +
        (state.reqsBehind > 0
          ? ` ${state.reqsBehind} of them are not fullfilled. Visitors are not happy 😠`
          : "")
      );
    },
    ontick: (state) => {
      if (state.phase !== 1) {
        return;
      }

      const canFullfill = Math.min(
        state.appservers * REQS_PER_APP,
        state.database * REQS_PER_DB
      );
      state.reqsCanFullfill = canFullfill;
    },
  },
  {
    id: "followers",
    btnvisible(state) {
      return state.requestsPerSecond && state.phase === 1;
    },
    text: (state) => {
      const followers = state.followers || 0;
      return `You have ${followers} followers.`;
    },
    topvisible: (state) => state.followers && state.phase === 1,
    toptitle: (state) => (state.followers ? "Followers" : null),
    topvalue: (state) => state.followers || 0,
    ontick: (state) => {
      if (state.phase !== 1) {
        return;
      }
      const reqs = state.requestsPerSecond || 0;
      const behind = Math.max(0, state.reqsBehind || 0);
      const behindPercent = behind / (reqs + behind);
      state.followers = state.followers + state.requestsPerSecond / 100;
      if (behind) {
        state.followers -= (state.followers * behindPercent) / 100;
      }
      state.followers = Math.round(state.followers);
      if (state.followers < 1) {
        state.followers = 1;
      }
      if (state.followers > 1000) {
        state.phase = 2;
      }
    },
  },
  {
    id: "presidentCamp",
    btnvisible(state) {
      return state.followers > 10000 && !state.presidentCampignResult;
    },
    canclick: (state) => true,
    btntitle(state) {
      return `Run presidential campaign`;
    },
    onclick(state) {
      const rate = state.presidentSupportRate || 0;
      state.presidentSupportRate =
        Math.round(100 * (rate + Math.random() * 1.5)) / 100;
      if (state.presidentSupportRate >= 50) {
        // You win
        state.presidentCampignResult = "win";
      }
    },
    ontick(state) {
      const rate = state.presidentSupportRate || 0;
      if (!rate) {
        state.presidentCampignDaysLeft = 0;
        return;
      }
      const left = state.presidentCampignDaysLeft || 30;
      state.presidentCampignDaysLeft = left - 1;
      if (state.presidentCampignDaysLeft <= 0 && rate < 50) {
        // you lost
        state.presidentCampignResult = "lost";
      }
    },
    text: (state) => {
      return `You decided to become President of the Internet. Your support rate: ${
        state.presidentSupportRate || 0
      }% and you need more than 50% to win. You have ${
        state.presidentCampignDaysLeft || 30
      } days left.`;
    },
  },
  {
    id: "campLost",
    btnvisible(state) {
      return state.presidentCampignResult === "lost";
    },
    btntitle(state) {
      return `You lost, try again`;
    },
    onclick(state) {
      state.presidentSupportRate = 0;
      state.presidentCampignResult = null;
      state.presidentCampignDaysLeft = 0;
    },
  },
  {
    id: "campWin",
    btnvisible(state) {
      return state.presidentCampignResult === "win";
    },
    btntitle(state) {
      return `You win, mr. President.`;
    },
    onclick(state) {
      state.presidentSupportRate = 0;
      state.presidentCampignResult = null;
      state.presidentCampignDaysLeft = 0;
    },
  },
];
