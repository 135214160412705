import React, { useEffect, useRef } from 'react';
import {  Animated, } from 'react-native';

export const useNewTargetAnimation = (state) => {
  const fadeAnim = useRef(new Animated.Value(0)).current;

  const fadeIn = () => {
    Animated.sequence([
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 3000,
      }),
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 3000,
      }),
    ]).start();
  };
  const style = {
    opacity: fadeAnim.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 1],
    }),
  };

  useEffect(() => {
    fadeIn();
  }, [state.hosts]);

  // console.log('==============================', style)
  return style;
};
